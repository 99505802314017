import React from 'react';
import XDSLink from '../components/Elements/Link';
import MotionByLine from '../components/Motion/ByLineFadeIn';
import MotionFadeIn from '../components/Motion/FadeIn';
import Footer from '../components/Footer/Footer';
import './CTAPage.css';

const CTAPage = (props) => {
  const imageClasses = `h-44 sm:h-72 md:h-${props.bodyImage.size} w-full md:w-auto max-w-md xl:max-w-full mx-auto`;

  return (
    <div className="relative w-full bg-material-theme1">
      <div className="px-3 mx-auto sm:w-full h-full flex flex-wrap content-center md:h-screen md:w-8/12 xl:w-8/12 max-w-xs md:max-w-full">
        <div className="w-auto sm:w-full z-30 md:h-auto m-auto md:w-1/2">
          <div>
            <MotionFadeIn delay={ 0.35 } className="mb-4 text-md sm:text-xl md:text-4xl lg:text-3xl xl:text-5xl font-brand">
              {props.title}
            </MotionFadeIn>
          </div>
          <div className="opacity-60 hidden sm:block">
            <MotionByLine delay={ 0.5 } className="text-md md:text-lg lg:text-xl font-normal">
              {props.lineArray}
            </MotionByLine>
          </div>
          <div className="relative opacity-60 mr-10 block sm:hidden">
            <MotionFadeIn delay={ 0.5 } className="font-normal">
              {props.lineArray.join(' ')}
            </MotionFadeIn>
          </div>
          <div>
            <MotionFadeIn delay={ 0.75 } className="button-holder pt-6">
              <XDSLink
                href={ props.cta }
                target="_blank"
                theme="theme1"
                text="View Job Openings"
                className="ring-offset-material-theme1 text-sm sm:text-base"
              />
            </MotionFadeIn>
          </div>
          <div className="md:hidden m-auto">
            <Footer />
          </div>
        </div>
        <div className="w-auto sm:w-full mx-auto max-w-xs md:max-w-lg lg:max-w-4xl order-first lg:order-last lg:w-1/2">
          <MotionFadeIn delay={ 0.35 }>
            <img
              src={ props.bodyImage.src }
              className={ imageClasses }
              alt={ props.bodyImage.alt }
            />
          </MotionFadeIn>
        </div>
      </div>
    </div>
  );
};
export default CTAPage;
