import React from 'react';
import './GalleryPage.css';

const GalleryPage = (props) => {
  const items = props.body ? props.body : [];
  const lgClass = (index) => (((index + 1) % 2 === 0) ? 'lg:trans-up' : 'lg:trans-dn');
  const smClass = (index) => (((index - 1) % 3 === 0) ? 'trans-up' : 'trans-dn');
  return (
    <div className="relative w-full h-full bg-fill-inverse-base">
      <div className="tile-gallery gallery-grid absolute container 2xl mx-auto content-center grid grid-cols-3 h-screen w-full md:px-15 lg:px-20 gap-6 xl:gap-8 md:relative sm:grid-cols-4">
        {items.map((child, index) => {
          const bgColor = child.color ? child.color : null;
          const bgImage = child.background_image ? `url(${child.background_image})` : null;
          const bgPosition = child.background_position ? child.background_posiiton : 'bg-right';
          const tileImage = child.image ? child.image : null;
          return (
            <div
              key={ `card-${index + 1}` }
              className={
                `gallery-card flex-center corner-radius-large overflow-hidden bg-cover ${bgPosition} ${smClass(index)} ${lgClass(index)}`
              }
              style={ { backgroundColor: bgColor, backgroundImage: bgImage } }
            >
              {tileImage ? <img alt={ child.alt_text } src={ child.image } /> : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default GalleryPage;
