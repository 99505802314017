import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ComcastLogo } from '../../images/Comcast.svg';
import './Header.css';
import { Context } from '../../store';

const Header = () => {
  const { store } = useContext(Context);

  return (
    <Link to="/" alt="Homepage" className="absolute z-30 pt-10 left-0 right-0">
      <ComcastLogo className={ `w-24 sm:ml-12 ml-8 logo ${store.theme}` } />
    </Link>
  );
};
export default Header;
