import React, { useState, useContext, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import CarouselItem from './CarouselItem';
import Pager from '../Pager/Pager';
import Footer from '../Footer/Footer';
import './Carousel.css';
import { Context } from '../../store';

// this is carousel container, this is where all functionality should live.
const Carousel = ({ data = [] }) => {
  const { dispatch } = useContext(Context);
  const [ activeIndex, setActiveIndex ] = useState(0);
  const [ passTime, setPassTime ] = useState(null);
  const [ passDelta, setPassDelta ] = useState(0);
  const [ scrollUp, setScrollUp ] = useState(null);
  const count = data.length;

  useEffect(() => {
    dispatch({ type: data[activeIndex].theme });
  }, [ activeIndex ]);

  // this is ran when clicked on prev, next, or a slide specific button
  const updateIndex = (indx) => {
    let newIndex = indx;
    if (indx < 0) {
      newIndex = 0;
    } else if (indx >= count) {
      newIndex = count - 1;
    }
    setActiveIndex(newIndex);
  };

  const didDeltaAccel = (a, b) => Math.abs(a) > Math.abs(b);

  const didReverse = (e) => {
    const prevScroll = scrollUp;
    setScrollUp((e?.deltaY > 0));
    const changeDir = prevScroll !== (e?.deltaY > 0);
    return changeDir;
  };

  const throttle = (e, fn, wait = 500) => {
    const accel = didDeltaAccel(e?.deltaY, passDelta);
    const passThrottle = Math.round(e?.timeStamp) > passTime + wait;
    if (accel || didReverse(e)) {
      setPassTime(Math.round(e?.timeStamp));
      if ((didReverse(e)) || !passTime || passThrottle) {
        if (fn) { fn(); }
      }
    }
    setPassDelta(e?.deltaY);
  };

  const onWheel = (e) => {
    const indexChange = e?.deltaY > 0 ? 1 : -1;
    updateIndex(activeIndex + indexChange);
  };

  const onKeyPress = (e) => {
    const isUp = e.keyCode === 38;
    const isDn = e.keyCode === 40;
    // eslint-disable-next-line no-nested-ternary
    const indexChange = isUp ? -1 : isDn ? 1 : 0;
    updateIndex(activeIndex + indexChange);
  };

  // https://www.npmjs.com/package/react-swipeable
  // setting swipable plugin, see link above for further detail around options
  const handlers = useSwipeable({
    onSwipedLeft: () => throttle(updateIndex(activeIndex + 1)),
    onSwipedUp: () => throttle(updateIndex(activeIndex + 1)),
    onSwipedRight: () => throttle(updateIndex(activeIndex - 1)),
    onSwipedDown: () => throttle(updateIndex(activeIndex - 1)),
  });

  return (
    <div
      // injects where swipeable will be active
      { ...handlers }
      className="carousel"
      onWheel={ (e) => throttle(e, () => onWheel(e)) }
      onKeyDown={ (e) => onKeyPress(e) }
      tabIndex={ -1 }
    >
      <div className="inner relative">
        {data.map((item, index) => {
          const active = activeIndex === index ? 'active' : '';
          return (
            <CarouselItem
              key={ `item-${index + 1}` }
              active={ active }
              updateIndex={ updateIndex }
              { ...item }
            />
          );
        })}

      </div>
      <Pager activeIndex={ activeIndex } slides={ data } updateIndex={ (e) => updateIndex(e) } />
      <div className="hidden md:block">
        { activeIndex + 1 === count ? <Footer /> : null }
      </div>
    </div>
  );
};

export default Carousel;
