import CTAPage from './CTAPage';
import GalleryPage from './GalleryPage';
import HeroPage from './HeroPage';

const Pages = {
  CTAPage,
  GalleryPage,
  HeroPage,
};

export default Pages;
