/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React from 'react';

export const initialState = { theme: 'light' };

export const reducer = (state, action) => {
  switch (action.type) {
    case 'reset':
      return initialState;
    case 'light':
      return { theme: state.theme = 'light' };
    case 'dark':
      return { theme: state.theme = 'dark' };
    default:
      return state;
  }
};

export const Context = React.createContext();
