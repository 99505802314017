import React from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const MotionByLine = ({
  children, delay = 0, speed = 1, className = '',
}) => {
  const [ ref, inView ] = useInView();

  const initStyle = {
    opacity: 0,
    translateY: '2em',
  };
  const loadStyle = {
    opacity: 1,
    translateY: '0em',
  };

  const formatSlices = (arr) => arr.map((item, index) => (
    <motion.p
      ref={ ref }
      key={ `heroCopy-${index + 1}-${inView ? '1' : '0'}` }
      initial={ initStyle }
      animate={ loadStyle }
      exit={ initStyle }
      transition={ {
        delay: index * 0.075 + delay,
        duration: speed,
        ease: [ 0.16, 1, 0.3, 1 ],
      } }
    >
      <span className={ `whitespace-nowrap fade delay-${index}` }>
        {item}
      </span>
    </motion.p>
  ));

  return (
    <div className={ `${className} align-text-top` }>
      {formatSlices(children)}
    </div>
  );
};

export default MotionByLine;
