import React from 'react';
import MotionFadeIn from '../components/Motion/FadeIn';
import MotionByLine from '../components/Motion/ByLineFadeIn';
import { ReactComponent as DownArrow } from '../images/ic_arrow_down_outline_inverse.svg';
import './HeroPage.css';

const HeroPage = (props) => (
  <div className="relative w-full h-screen animate-bg">
    <div className="flex flex-wrap overflow-hidden mx-auto content-center h-screen  w-full">
      <div className="absolute top-0 left-0 h-full title-holder flex justify-center items-center mx-8 sm:mx-12">
        <div className="centered-content relative flex-1">
          <h1 className="animated_blur text-display1 leading-none z-10 opacity-25 text-bg">{props.title}</h1>
          <div className="md:absolute md:bottom-0 md:right-0 quote">
            <MotionByLine delay={ 0.7 } className="sm:block hidden text-display2 z-30">
              {props.lineArray}
            </MotionByLine>
            <MotionFadeIn delay={ 0.7 } className="pt-6 mr-12 px-2 block sm:hidden text-headline2 z-30 max-w-sm">
              {props.lineArray.join(' ')}
            </MotionFadeIn>
            <MotionFadeIn delay={ 1.10 }>
              <button aria-label="Learn More" type="button" className="learn-more flex items-center mt-8 cursor-pointer" onClick={ () => props.updateIndex(1) }>
                <DownArrow className="down-arrow box-content mr-4 h-24 w-24" />
                <span className="hidden md:block">Learn More</span>
              </button>
            </MotionFadeIn>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HeroPage;
