// import * as Tokens from '@xds/base-web-tokens-json/index';
import GalleryImages from './images/jacksGallery';
import jobsArtwork from './images/jobs_artwork.svg';
import Pages from './pages';

// const {
//   colors: {
//     palette,
//   },
// } = Tokens;

const GalleryData = [
  {
    alt_text: 'modem',
    background_image: GalleryImages.modem,
  },
  {
    alt_text: 'icons',
    background_image: GalleryImages.icons,
  },
  {
    alt_text: 'remote',
    background_image: GalleryImages.remote,
  },
  {
    alt_text: 'xds',
    background_image: GalleryImages.xds,
  },
  {
    alt_text: 'Red Palette',
    background_image: GalleryImages.redPalette,
  },
  {
    alt_text: 'design is universal',
    background_image: GalleryImages.brail,
  },
  {
    alt_text: 'blue lady',
    background_image: GalleryImages.blueLady,
  },
  {
    alt_text: 'floating people',
    background_image: GalleryImages.floatingPeople,
  },
  {
    alt_text: 'green lady',
    background_image: GalleryImages.greenLady,
  },
  {
    alt_text: 'Packaging',
    background_image: GalleryImages.packaging,
  },
  {
    alt_text: 'cards',
    background_image: GalleryImages.cards,
  },
  {
    alt_text: 'camera',
    background_image: GalleryImages.camera,
  },
];

const CarouselData = [
  {
    // Slide 1
    title: 'Experience Design',
    lineArray: [
      'We dream of solutions—and make',
      'real products and experiences—to',
      'connect people to more of what',
      'they love.',
    ],
    template: Pages.HeroPage,
    theme: 'light',
  },
  {
    // Slide 2,
    title: 'slide2',
    body: GalleryData,
    template: Pages.GalleryPage,
    backgroundColor: 'bg-grey-10',
    theme: 'dark',
  },
  {
    // Slide 3
    title: 'Like what you see?',
    lineArray: [
      'Like what you see? We’d love to hear from ',
      'you. Check out our current job openings ',
      'at Comcast Careers.',
    ],
    cta: 'https://jobs.comcast.com/',
    template: Pages.CTAPage,
    bodyImage: {
      src: jobsArtwork,
      type: 'svg',
      size: '94',
      alt: 'Dancing People',
    },
    backgroundColor: 'bg-grey-10',
    theme: 'dark',
  },

];

export default CarouselData;
