import React, { useContext } from 'react';
import { Context } from '../../store';
import './Footer.css';

const Footer = () => {
  const { store } = useContext(Context);
  const fontColor = store.theme === 'light'
    ? 'text-inverse-base'
    : 'text-neutral-base';

  const borderColor = store.theme === 'light'
    ? 'border-stroke-inverse-base'
    : 'border-stroke-neutral-base';

  return (
    <div className={ `theme-${store.theme}  md:absolute w-full md:w-auto z-30 py-5 md:py-10 m-auto md:text-left md:right-0 bottom-0 px-2 md:px-16` }>
      <nav className="relative align-center">
        <ul className="flex-row md:flex md:px-10 lg:px-16">
          <li className={ `py-1 md:px-4 ${fontColor}` }>
            &copy;
            {' '}
            {new Date().getFullYear()}
            {' '}
            COMCAST
          </li>
          <li className={ `py-1 md:px-4 ${fontColor} ${borderColor}` }>
            <a className="custom-underline" href="https://www.xfinity.com/corporate/privacy" target="_blank" rel="noreferrer">Privacy</a>
          </li>
          <li className={ `py-1 md:px-4 ${fontColor} ${borderColor}` }>
            <a className="custom-underline" href="https://www.xfinity.com/corporate/legal/visitoragreement" target="_blank" rel="noreferrer">Terms</a>
          </li>
          <li className={ `py-1 md:px-4 ${fontColor} ${borderColor}` }>
            <a className="custom-underline" href="https://www.xfinity.com/privacy/manage-preference" target="_blank" rel="noreferrer">Do Not Sell My Personal Information</a>
          </li>
        </ul>
      </nav>
    </div>
  );
};
export default Footer;
