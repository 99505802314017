import React from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const MotionFadeIn = ({
  children, delay = 0, speed = 0.5, className = '', direction = 'up',
}) => {
  const [ ref, inView ] = useInView();

  const initStyle = { opacity: 0, transform: `translateY(${direction === 'up' ? '' : '-'}1em)` };
  const loadStyle = { opacity: 1, transform: 'translateY(0em)' };

  return (
    <motion.div
      key={ `motion-${inView ? '1' : '0'}` } // used to reset lifecycle when exiting the view
      ref={ ref }
      initial={ initStyle }
      animate={ loadStyle }
      exit={ initStyle }
      transition={ {
        delay,
        duration: speed,
        ease: [ 0.16, 1, 0.3, 1 ],
      } }
      className={ className }
    >
      {children}
      {inView ? '' : 'out' }
    </motion.div>
  );
};

export default MotionFadeIn;
