import React from 'react';

const CarouselItem = (props) => {
  const defaultProps = {
    active: props.active,
    alignment: 'left',
    title: '',
    body: '',
    bgColor: 'bg-fill-theme1-base',
    bodyImage: null,
    bgImage: false,
    theme: 'light',
  };

  const bgImg = props.bgImage ? props.bgImage : false;
  const bgCol = props.theme === 'light' ? 'bg-fill-theme1-base' : 'bg-fill-grey-base';
  const textCol = props.theme === 'light' ? 'text-inverse-base' : 'text-grey-base';
  const style = { backgroundImage: `url(${bgImg})` };

  return (
    <div
      className={
        `carousel-item flex flex-row absolute h-screen overflow-hidden w-full align-${
          props.alignment || defaultProps.alignment
        } ${bgCol} ${textCol}${
          props.bgImage ? ' bg-center bg-cover' : ''
        }${props.active ? ' active' : ''}`
      }
      style={ style }
    >
      <props.template { ...props } />
    </div>
  );
};
export default CarouselItem;
