import React, { useEffect, useState, useContext } from 'react';
import './Pager.css';
import { Context } from '../../store';
import { ReactComponent as ChevIcon } from '../../images/caret.svg';

const defaultProps = {
  activeIndex: 0,
  slides: [],
  showArrows: true,
};

const Pager = (props = defaultProps) => {
  const [ prevIndex, setIndex ] = useState(0);
  const [ dotWidth, setDotWidth ] = useState('20px');
  const [ dotLeft, setDotLeft ] = useState('0px');
  const count = props.slides.length;
  const { store } = useContext(Context);
  const hoverStyle = store.theme === 'light'
    ? 'hover:bg-fill-inverse-hover focus:bg-transparent'
    : 'hover:bg-fill-neutral-hover focus:bg-transparent';
  const fillStyle = store.theme === 'light'
    ? 'bg-current bg-fill-inverse-base hover:bg-fill-inverse-hover focus:bg-transparent'
    : 'bg-current bg-fill-neutral-base hover:bg-fill-neutral-hover';
  const borderStyles = store.theme === 'light'
    ? 'border-current border-stroke-inverse-base focus:border-stroke-inverse-base'
    : 'border-current border-stroke-neutral-base focus:border-stroke-neutral-base';

  const calcDotLoc = (index) => `calc(${index * 4}px + ${index * 2}rem)`;

  useEffect(() => {
    setIndex(props.activeIndex);
    const dw = '20px';
    const dir = prevIndex > props.activeIndex;
    const diff = Math.abs(prevIndex - props.activeIndex);
    const dotW = diff > 0 ? `calc(${(diff + 1) * 20}px + ${diff}rem)` : dw;
    setDotWidth(dotW);
    setTimeout(() => {
      setDotWidth(dw);
    }, 500);
    if (dir) {
      setDotLeft(calcDotLoc(props.activeIndex));
    }
    setTimeout(() => {
      setDotLeft(calcDotLoc(props.activeIndex));
    }, 500);
  }, [ props.activeIndex ]);

  return (
    <div className={ `indicators z-40 absolute flex justify-center -right-16 items-center bottom-0 ${borderStyles}` }>
      <button
        className={ props.activeIndex === 0 ? 'opacity-0' : 'opacity-60 hover:opacity-100' }
        type="button"
        onClick={ () => {
          props.updateIndex(props.activeIndex - 1);
        } }
      >
        <ChevIcon className={ `${store.theme}` } />
        <span className="sr-only">Previous</span>
      </button>

      <div className="pager-holder relative">
        <div
          className={ `pager-dot absolute border-2 w-5 h-5 rounded-large ${fillStyle} ${borderStyles}` }
          style={ { left: dotLeft, width: dotWidth } }
        />
        {props.slides.map((child, index) => (
          <button
            type="button"
            key={ `pg-${index + 1}` }
            className={ `border-2 ${borderStyles} ${hoverStyle}  w-5 h-5 rounded-large text-opacity-0 ${index === props.activeIndex ? 'active' : ''}` }
            onClick={ () => {
              props.updateIndex(index);
            } }
          >
            <span className="sr-only">
              Page
              {index + 1}
            </span>
          </button>
        ))}
      </div>

      <button
        type="button"
        className={ props.activeIndex === count - 1 ? 'opacity-0' : 'opacity-60 hover:opacity-100' }
        onClick={ () => {
          props.updateIndex(props.activeIndex + 1);
        } }
      >
        <ChevIcon className={ `${store.theme} transform rotate-180` } />
        <span className="sr-only">Next</span>
      </button>
    </div>
  );
};

export default Pager;
