import redPalette from './redPalette.png';
import camera from './camera.png';
import brail from './brail.png';
import modem from './modem.png';
import cards from './cards.png';
import floatingPeople from './floatingPeople.png';
import greenLady from './greenLady.png';
import blueLady from './blueLady.png';
import icons from './icons.png';
import packaging from './packaging.png';
import remote from './remote.png';
import xds from './xds.png';

const GalleryImages = {
  modem,
  redPalette,
  camera,
  icons,
  brail,
  cards,
  remote,
  packaging,
  greenLady,
  blueLady,
  xds,
  floatingPeople,
};

export default GalleryImages;
