import React from 'react';
import './ShadowShape.css';

const ShadowShape = () => (
  <div className="shadow-holder z-20 -inset-4 overflow-hidden fixed w-auto h-auto ">
    <svg className="arrow min-h-full min-w-full absolute w-auto h-auto opacity-20 -top-2 -left-2" role="img" focusable="false" data-name="arrow" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMax slice" viewBox="0 0 100 50">
      <defs>
        <filter id="f1" x="0" y="0">
          <feGaussianBlur in="SourceGraphic" stdDeviation="1" />
        </filter>
      </defs>
      <polygon filter="url(#f1)" points="0 0 0 50 10 50 10 5 100 30 100 0 0 0" />
    </svg>
  </div>
);

export default ShadowShape;
