import React from 'react';

const XDSLink = ({
  theme = 'theme1',
  outline = false,
  text = 'click here',
  chevron = false,
  ...props
}) => {
  const isInverse = theme === 'inverse' ? 'neutral' : 'inverse';
  const textColor = outline ? theme : isInverse;
  const textColorInverted = theme === 'inverse' ? 'neutral' : 'inverse';
  const fillStyle = outline
    ? `text-${textColor}-base bg-fill-transparent border-stroke-theme-base hover:text-${textColorInverted}-hover focus:text-${textColorInverted}-focus active:text-${textColorInverted}-active`
    : `text-${textColor}-base bg-fill-${theme}-base border-stroke-${theme}-base`;
  const interactionStates = `hover:bg-fill-${theme}-hover hover:border-stroke-${theme}-hover focus:bg-fill-${theme}-focus focus:border-stroke-${theme}-focus focus:ring-2 focus:ring-${theme}-base active:border-stroke-${theme}-down active:bg-fill-${theme}-base`;

  return (
    <a href={ props.href } target={ props.target ? props.target : '_self' } className={ `${fillStyle} ${interactionStates} transition-colors duration-500 border-2 py-4 px-8 text-button1 font-brand rounded-small outline-none ring-offset-2 ${props.className}` }>
      {text}
      {' '}
      {chevron ? ' >' : null}
    </a>
  );
};

export default XDSLink;
