import React, { useReducer } from 'react';
import {
  Routes,
  Route,
  Outlet,
} from 'react-router-dom';
import XDSLink from './components/Elements/Link';
import Header from './components/Header/Header';
import Carousel from './components/Carousel/Carousel';
import ShadowShape from './components/ShadowShape/ShadowShape';
import CarouselData from './data';
import Footer from './components/Footer/Footer';
import { Context, initialState, reducer } from './store';
import MotionFadeIn from './components/Motion/FadeIn';

export default function App() {
  const [ store, dispatch ] = useReducer(reducer, initialState);
  return (
    <Context.Provider value={ { store, dispatch } }>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={ <Layout /> }>
            <Route index element={ <Home /> } />
            {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          </Route>
          <Route path="*" element={ <NoMatch /> } />
        </Routes>
      </div>
    </Context.Provider>
  );
}
function Layout() {
  return (
    <>
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}
      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <Outlet />
      <ShadowShape />
    </>
  );
}
function Home() {
  return (
    <>
      <Carousel data={ CarouselData } />
    </>
  );
}
function NoMatch() {
  return (
    <div className="h-screen w-screen flex flex-col align-middle justify-center text-center bg-fill-theme1-base">
      <h1 className="text-display1 text-inverse-base leading-none opacity-25 text-bg-xl animated_blur lg:absolute ">
        404
      </h1>
      <div className="z-10 h-1/2 sm:h-auto ">
        <MotionFadeIn delay={ 0.7 }>
          <h2 className="text-display1 leading-none text-inverse-base">Woops!</h2>
        </MotionFadeIn>
        <MotionFadeIn delay={ 0.9 }>
          <h3 className="text-inverse-base text-md md:text-lg lg:text-xl mb-9">
            The page you were looking for does not exist.
          </h3>
        </MotionFadeIn>
        <MotionFadeIn delay={ 1.1 }>
          <XDSLink
            href="/"
            theme="inverse"
            text="Go to the home page"
            className="ring-offset-theme1-base text-sm sm:text-base"
          />
        </MotionFadeIn>
      </div>
      <Footer />
    </div>
  );
}
